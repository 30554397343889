export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Tipo de tratamiento",
          value: "treatmentType",
          sortable: false
        },
        {
          text: "Código acta CTC / RAF",
          sortable: false,
          value: "ctcCode"
        },
        {
          text: "Codigo medicamento",
          sortable: false,
          value: "medicineCode"
        },
        {
          text: "Nombre medicamento",
          value: "medicineName",
          sortable: false
        },

        {
          text: "Descripcion medicamento",
          value: "medicineDescription",
          sortable: false
        },
        {
          text: "Fecha de entrega",
          value: "dateDelivery",
          sortable: false
        },
        {
          text: "Fecha siguiente entrega",
          value: "nextDeliveryDate",
          sortable: false
        },
        {
          text: "Fecha fin tratamiento",
          value: "endTreatment",
          sortable: false
        },
        {
          text: "Nro entregas actuales",
          value: "currentDeliveries",
          sortable: false
        },
        {
          text: "Nro entregas totales",
          value: "totalDeliveries",
          sortable: false
        },
        {
          text: "Cantidad por entrega",
          value: "quantityDelivery",
          sortable: false
        },
        {
          text: "Tiempo de entrega (meses)",
          value: "deliveryTime",
          sortable: false
        }
      ],
      itemsData: [
        {
          treatmentType: "lol",
          ctcCode: "20190422143011529576",
          medicineCode: "26464",
          medicineName: "VITAMINA D3 (COLECALCIFEROL)",
          medicineDescription: "	2000 UI CAPSULA BLANDA",
          dateDelivery: "2019/07/04	",
          nextDeliveryDate: "2019/07/26	",
          endTreatment: "2019/09/04	",
          currentDeliveries: "2",
          totalDeliveries: "3",
          quantityDelivery: "60",
          deliveryTime: "1"
        }
      ]
    };
  },
  props: {},
  watch: {},
  mounted() {},
  methods: {
    onRowClick(item) {
      this.$emit("row-click", item);
    }
  }
};
