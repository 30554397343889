var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "material-card",
    { staticClass: "mx-4", attrs: { color: "primary" } },
    [
      _c("v-data-table", {
        attrs: {
          "rows-per-page-text": "Registros por página",
          headers: _vm.headers,
          items: _vm.itemsData,
        },
        scopedSlots: _vm._u([
          {
            key: "headerCell",
            fn: function ({ header }) {
              return [
                _c("span", {
                  staticClass: "subheading font-weight-light text-primary",
                  domProps: { textContent: _vm._s(header.text) },
                }),
              ]
            },
          },
          {
            key: "items",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    staticClass: "row",
                    on: {
                      click: function ($event) {
                        return _vm.onRowClick(props.item)
                      },
                    },
                  },
                  [
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.treatmentType)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.ctcCode)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.medicineCode)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.medicineName)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.medicineDescription)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.dateDelivery)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.nextDeliveryDate)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.endTreatment)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.currentDeliveries)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.totalDeliveries)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.quantityDelivery)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.deliveryTime)),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }